import { useEffect } from 'react'
import { a, SpringValue } from '@react-spring/web'
import tailwindConfig from 'theme'
import clsx from 'clsx'
import { init } from './initShape'

type Props = {
  pathKey: string
  className?: string
  animatedProps?: {
    transform: SpringValue<string>
  }
  color?: string
}

export const OrganicShape = ({
  pathKey,
  className,
  animatedProps,
  color = tailwindConfig.theme.colors.secondary.sand,
}: Props) => {
  const pathId = `animated-blob-path-${pathKey}`
  const gradientId = `radial-gradient-${pathKey}`

  useEffect(() => {
    init(pathId)
  }, [pathId])

  return (
    <a.svg
      style={animatedProps}
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('max-h-full max-w-full text-secondary-sand', className)}
    >
      <defs>
        <radialGradient id={gradientId} cx="10%" fx="10%" fr="0%" r="80%">
          <stop offset="20%" stopColor={color} />
          <stop offset="95%" stopColor={color} />
        </radialGradient>
      </defs>
      <path id={pathId} d="" fill={`url('#${gradientId}') rgb(255,255,255)`} />
    </a.svg>
  )
}
