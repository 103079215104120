import clsx from 'clsx'
import { CSSProperties } from 'react'

type Props = {
  color: string
  className?: string
  svgClasses?: string
  isBottom?: boolean
}

export const BottomShape = ({ color, className, svgClasses, isBottom }: Props) => {
  return (
    <div className={clsx('absolute w-full bg-primary-white', className)}>
      <svg
        className={svgClasses}
        preserveAspectRatio="none"
        viewBox={isBottom ? '0 0 1440 329' : '0 0 1440 900'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-19.367 593.078c-34.737-282.822 35.749-392.434 226.008-472.442C514.01-8.62 1038.61-61.664 1280.2 103.853c301.98 206.896 174.27 537.454 30.34 798.35-126.89 230.037-338.92 344.087-493.779 396.567C352.738 1456.04 24.054 946.606-19.367 593.078Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
