import { useEffect } from 'react'
import { a, SpringValue } from '@react-spring/web'
import tailwindConfig from 'theme'
import clsx from 'clsx'
import { init } from '~/elements/OrganicShape/initShape'
import { useMedia } from 'hooks/useMedia'

type Props = {
  pathKey: string
  className?: string
  animatedProps?: any
  color?: string
}

export const OrganicShape = ({
  pathKey,
  className,
  animatedProps,
  color = tailwindConfig.theme.colors.secondary.sand,
}: Props) => {
  const isDesktop = useMedia('lgx')
  const isTablet = useMedia('md')

  const pathId = `animated-blob-path-${pathKey}`
  const gradientId = `radial-gradient-${pathKey}`

  useEffect(() => {
    init(pathId)
  }, [pathId])

  return (
    <a.svg
      style={animatedProps}
      viewBox={isDesktop ? '0 0 190 200' : isTablet ? '0 0 230 300' : '0 0 220 400'}
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('max-h-full max-w-full text-secondary-sand', className)}
    >
      <defs>
        <radialGradient id={gradientId} cx="10%" fx="10%" fr="0%" r="80%">
          <stop offset="20%" stopColor={color} />
          <stop offset="95%" stopColor={color} />
        </radialGradient>
      </defs>
      <g
        transform={
          isDesktop
            ? 'translate(10 20)'
            : isTablet
            ? 'translate(10 40) scale(1.25)'
            : 'translate(0 80) scale(1.3)'
        }
      >
        <path id={pathId} d="" fill={`url('#${gradientId}') rgb(255,255,255)`} />
      </g>
    </a.svg>
  )
}
