import { RteRteLinks, useVideoModuleQuery } from '@contentfulTypes'
import { Headline } from 'elements/Headline/Headline'
import { VideoPlayer } from '~/elements/VideoPlayer/VideoPlayer'
import tailwindConfig from '~/theme'
import { useMarginMaker } from 'hooks/useMarginMaker'
import clsx from 'clsx'
import { useMedia } from 'hooks/useMedia'
import { Media } from '~/lib/Media'
import { useInView } from 'react-intersection-observer'
import { useSpring, easings } from '@react-spring/web'
import { OrganicShape } from 'elements/OrganicShape/OrganicShape'
import { StaticShape } from '~/elements/OrganicShape/StaticShape'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
type Props = {
  id: string
}
const VideoModule = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const isDesktop = useMedia('lg')
  const router = useRouter()
  const { data } = useVideoModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const blowUp = useSpring({
    config: { easing: easings.easeOutBounce, mass: 10, tension: 100, friction: 40 },
    transform: inView ? 'scale(1)' : 'scale(0)',
    transformOrigin: 'center',
  })
  const margin = useMarginMaker(
    data?.video?.marginBottom as string,
    data?.video?.marginBottomMobile as string,
  )
  if (!data?.video) return null

  const {
    videoFile,
    placeholderImage,
    headline,
    organicShapeColor,
    handleOrganicShape,
    autoPlay,
    unmuted,
    videoWidth = 100,
    hideControls,
    sys,
    youTubeUrl,
    mobileVideoAboveText,
    desktopVideoLeft,
    rte,
  } = data?.video

  const shapeColorValue = organicShapeColor
    ? // @ts-ignore
      tailwindConfig.theme.colors.secondary[organicShapeColor]
      ? // @ts-ignore
        tailwindConfig.theme.colors.secondary[organicShapeColor]
      : tailwindConfig.theme.colors.secondary.sand
    : tailwindConfig.theme.colors.secondary.sand

  return (
    <section ref={inViewRef} style={margin} className="content-width relative">
      <Jumplink id={id} />
      {headline ? (
        <Headline
          type="h2"
          className="z-1 mx-4 mt-6 mb-4 bg-transparent lg:mx-20 lg:mb-10 lg:mt-12"
        >
          {headline}
        </Headline>
      ) : null}
      <div className={`relative max-h-[100vh] w-full`}>
        <Media greaterThanOrEqual="lg">
          {handleOrganicShape === 'static' ? (
            <div className="absolute right-0 top-0 -z-10 h-full w-full max-w-[100vw] overflow-hidden lg:-translate-y-1/2 lg:overflow-visible">
              <StaticShape className={clsx(``)} color={shapeColorValue} />
            </div>
          ) : null}
        </Media>
        {rte ? (
          <div
            className={clsx(
              'flex flex-col items-center lg:flex-row',
              mobileVideoAboveText && 'flex-col-reverse',
              desktopVideoLeft && 'lg:flex-row-reverse',
            )}
          >
            <div className={clsx('flex-1 p-4 lg:p-10')}>
              <RTEParser rteJSONContent={rte.json} links={rte.links as RteRteLinks} />
            </div>
            <div className={clsx('flex-1 ')}>
              <VideoPlayer
                src={youTubeUrl || videoFile?.url}
                hideControls={hideControls ? hideControls : false}
                placeholderUrl={placeholderImage?.url}
                placeholderAlt={placeholderImage?.title}
                autoPlay={autoPlay ? autoPlay : false}
                unmuted={unmuted ? unmuted : false}
                placeholderSizes={`(min-width: 1920px) 1770px,(min-width: 1024px) 92vw, 100vw`}
              />
            </div>
          </div>
        ) : (
          <div className="flex aspect-square h-full w-full justify-center overflow-hidden md:aspect-video">
            <div
              style={{
                width: `${isDesktop ? videoWidth : 100}%`,
                maxHeight: isDesktop ? 'calc(100vh - 91px)' : 'calc(100vh - 110px)',
              }}
            >
              <VideoPlayer
                src={youTubeUrl || videoFile?.url}
                hideControls={hideControls ? hideControls : false}
                placeholderUrl={placeholderImage?.url}
                placeholderAlt={placeholderImage?.title}
                autoPlay={autoPlay ? autoPlay : false}
                unmuted={unmuted ? unmuted : false}
                placeholderSizes={`(min-width: 1920px) 1770px,(min-width: 1024px) 92vw, 100vw`}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default VideoModule
