import { useTranslation } from 'next-i18next'
import { ProductsSlider } from '~/modules/ProductsSlider/ProductsSlider'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { TeaserItem, useProductsSliderQuery } from '@contentfulTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createMagentoClient } from '~/graphql/magentoClient'
import {
  ConfigurableProduct,
  useRecentlyViewedProductsQuery,
  useRecentlyViewedProductsViaSkuQuery,
} from '@magentoTypes'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { LoadingItems } from './components/LoadingItems'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import clsx from 'clsx'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
  className?: string
}

const RecommendedProducts = ({ id, className }: Props) => {
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const { data: contentfulData, isLoading: isLoadingContentful } = useProductsSliderQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: finalLocale,
    },
    { enabled: !!id },
  )

  const margin = useMarginMaker(
    contentfulData?.productsSlider?.marginBottom as string,
    contentfulData?.productsSlider?.marginBottomMobile as string,
  )

  const { data: recentlyViewedData, isLoading: isLoadingMagento } =
    useRecentlyViewedProductsViaSkuQuery(
      magentoClient,
      {
        skus: contentfulData?.productsSlider?.skus as string[],
      },
      {
        enabled:
          !!contentfulData?.productsSlider?.skus &&
          contentfulData?.productsSlider?.skus?.length > 0,
      },
    )
  const { data: recentlyViewedDataUrlKey, isLoading: isLoadingMagentoUrlKey } =
    useRecentlyViewedProductsQuery(
      magentoClient,
      {
        urlKeys: contentfulData?.productsSlider?.urlKey as string[],
      },
      {
        enabled:
          !!contentfulData?.productsSlider?.urlKey &&
          contentfulData?.productsSlider?.urlKey?.length > 0,
      },
    )
  const finalData = recentlyViewedData ? recentlyViewedData : recentlyViewedDataUrlKey
  const sortingArr = contentfulData?.productsSlider?.skus
  if (finalData && sortingArr) {
    finalData?.products?.items?.sort(
      (a, b) => sortingArr.indexOf(a?.sku as string) - sortingArr.indexOf(b?.sku as string),
    )
  }

  const loadingState = !!contentfulData?.productsSlider?.skus
    ? isLoadingMagento
    : isLoadingMagentoUrlKey
  return (
    <section
      style={margin}
      className={clsx(className, 'breakout-content-width max-w-[100vw] overflow-hidden')}
    >
      {isLoadingContentful || loadingState ? (
        <LoadingItems />
      ) : (
        <ProductsSlider
          products={finalData?.products?.items as ConfigurableProduct[]}
          headlineText={contentfulData?.productsSlider?.headline || ''}
          h1Headline={contentfulData?.productsSlider?.h1Headline || false}
          lastItemTeaser={contentfulData?.productsSlider?.lastTeaser as TeaserItem}
          firstItemTeaser={contentfulData?.productsSlider?.firstTeaser as TeaserItem}
        />
      )}
    </section>
  )
}

export default RecommendedProducts
