import NextLink from 'next/link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Document, Block, Inline, Text } from '@contentful/rich-text-types'
import { isExternal } from '~/lib/externalLink'
import { makeAbsolute } from '~/lib/makeAbsolute'
import { SeoText } from '../SeoText/SeoText'

export interface SeoTextLinks {
  entries: {
    block: any[]
    inline: any[]
    hyperlink: any[]
  }
  assets?: {
    block: any[]
    hyperlink: any[]
  }
}

interface SeoTextRendererProps {
  content: Document
  links?: SeoTextLinks
}

type NodeRenderer = (node: Block | Inline | Text, children: React.ReactNode) => React.ReactNode

interface CustomOptions extends Options {
  renderNode?: {
    [K in keyof Options['renderNode']]: NodeRenderer
  }
}

export const SeoTextRenderer = ({ content, links }: SeoTextRendererProps) => {
  const entryMap = new Map()

  if (links) {
    for (const entry of links.entries.block) {
      entryMap.set(entry?.sys.id, entry)
    }

    for (const entry of links.entries.inline) {
      entryMap.set(entry?.sys.id, entry)
    }

    for (const entry of links.entries.hyperlink) {
      entryMap.set(entry?.sys.id, entry)
    }
  }

  const options: CustomOptions = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node: Block, children: React.ReactNode) => (
        <h3 className="my-5 text-15-22-sg font-medium text-sg-black">{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (node: Block, children: React.ReactNode) => (
        <p className="text-15-22-sg font-extralight text-sg-black/75">{children}</p>
      ),
      [BLOCKS.UL_LIST]: (node: Block, children: React.ReactNode) => (
        <ul className="mt-5 list-disc pl-4 text-sg-black/75 marker:text-sg-black/75">{children}</ul>
      ),
      [INLINES.HYPERLINK]: (node: Inline, children: React.ReactNode) => {
        const { uri } = node.data
        const isExternalLink = isExternal(uri)

        if (isExternalLink) {
          return (
            <a
              href={uri}
              className="text-sg-black/75 underline decoration-sg-black/75"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          )
        }

        return (
          <NextLink
            href={makeAbsolute(uri)}
            className="text-sg-black/75 underline decoration-sg-black/75"
          >
            {children}
          </NextLink>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: (node: Inline, children: React.ReactNode) => {
        const entry = entryMap.get(node.data.target.sys.id)

        if (entry && entry.__typename === 'Page') {
          return (
            <NextLink
              href={makeAbsolute(entry.slug)}
              className="text-sg-black/75 underline decoration-sg-black/75"
            >
              {children}
            </NextLink>
          )
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node: Inline) => {
        const entry = entryMap.get(node.data.target.sys.id)

        if (entry?.__typename === 'SeoText') {
          if (!entry.seoText) return null

          return (
            <SeoText
              headline={entry.headline}
              seoText={entry.seoText.json}
              links={entry.seoText.links}
              isEmbedded={true}
            />
          )
        }

        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block) => {
        const entry = entryMap.get(node.data.target.sys.id)

        if (entry?.__typename === 'SeoText') {
          if (!entry.seoText) return null

          return (
            <SeoText
              headline={entry.headline}
              seoText={entry.seoText.json}
              links={entry.seoText.links}
              isEmbedded={true}
            />
          )
        }

        return null
      },
    },
    renderText: (text: string) => {
      return text?.split('\n').reduce((children: React.ReactNode[], textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return <>{documentToReactComponents(content, options)}</>
}
