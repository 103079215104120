import { useEditorialGalleryQuery } from '@contentfulTypes'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useInView } from 'react-intersection-observer'
import tailwindConfig from 'theme'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import clsx from 'clsx'

import { usePreviewMode } from '~/hooks/usePreviewMode'
import { Image } from '~/elements/Image/Image'
import { Headline } from 'elements/Headline/Headline'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { BottomShape } from './BottomShape'
import { BackgroundText } from './BackgroundText'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
  isDesktop?: boolean
}

const EditorialGalleryContent = ({ id, isDesktop }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data, error } = useEditorialGalleryQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const { ref: inViewRef, inView: containerInView } = useInView({
    threshold: isDesktop ? 0.6 : 0.15,
    triggerOnce: true,
  })

  const { ref: textRef, inView: textInView } = useInView({
    threshold: 1,
    triggerOnce: true,
  })
  gsap.registerPlugin(ScrollTrigger)
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    const element = ref.current!
    gsap.fromTo(
      element?.querySelector('#firstImage'),
      {
        y: isDesktop ? '100vh' : '50vh',
      },
      {
        y: isDesktop ? '15vh' : '20vh',
        scrollTrigger: {
          trigger: isDesktop
            ? element?.querySelector('#textContainer')
            : element?.querySelector('#textContainer'),
          start: 'top',
          end: 'bottom',
          scrub: 2,
          invalidateOnRefresh: true,
        },
      },
    )
  }, [isDesktop])

  useEffect(() => {
    const element = ref?.current!
    gsap.fromTo(
      element?.querySelector('#secondImage'),
      {
        y: isDesktop ? '50vh' : '20vh',
      },
      {
        y: isDesktop ? '18vh' : '10vh',
        scrollTrigger: {
          trigger: isDesktop
            ? element?.querySelector('#textContainer')
            : element?.querySelector('#textContainer'),
          start: 'top',
          end: 'bottom',
          scrub: 0.5,
          invalidateOnRefresh: true,
        },
      },
    )
  }, [isDesktop])

  useEffect(() => {
    const element = ref?.current!
    gsap.fromTo(
      element?.querySelector('#thirdImage'),
      {
        y: isDesktop ? '60vh' : '35vh',
      },
      {
        y: isDesktop ? '23vh' : '30vh',
        scrollTrigger: {
          trigger: isDesktop
            ? element?.querySelector('#innerContainer')
            : element?.querySelector('#text'),
          start: 'top',
          end: 'bottom',
          scrub: 1.5,
          invalidateOnRefresh: true,
        },
      },
    )
  }, [isDesktop])
  useEffect(() => {
    const element = ref?.current!
    gsap.fromTo(
      element?.querySelector('#fourthImage'),
      {
        y: isDesktop ? '30vh' : '30vh',
      },
      {
        y: isDesktop ? '-60vh' : '-20vh',
        scrollTrigger: {
          trigger: isDesktop
            ? element?.querySelector('#innerContainer')
            : element?.querySelector('#innerContainer'),
          start: 'top',
          end: 'bottom',
          scrub: isDesktop ? 2.3 : 1.7,
          invalidateOnRefresh: true,
        },
      },
    )
  }, [isDesktop])
  useEffect(() => {
    const element = ref?.current!
    gsap.fromTo(
      element?.querySelector('#fifthImage'),
      {
        y: isDesktop ? '20vh' : '-5vh',
      },
      {
        y: isDesktop ? '-10vh' : '-25vh',
        scrollTrigger: {
          trigger: element?.querySelector('#innerContainer'),
          start: 'top',
          end: 'bottom',
          scrub: 0.5,
          invalidateOnRefresh: true,
        },
      },
    )
  }, [isDesktop])
  useEffect(() => {
    const element = ref?.current!
    gsap.fromTo(
      element?.querySelector('#sixthImage'),
      {
        y: isDesktop ? '90vh' : '45vh',
      },
      {
        y: isDesktop ? '15vh' : '25vh',
        scrollTrigger: {
          trigger: element?.querySelector('#innerContainer'),
          start: 'top',
          end: 'bottom',
          scrub: 1.2,
          invalidateOnRefresh: true,
        },
      },
    )
  }, [isDesktop])

  if (!data?.editorialGallery || error) return null
  const {
    headline,
    text,
    cta,
    backgroundText,
    backgroundColor,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
  } = data?.editorialGallery

  const hasSmallerBG = !image5 && !image6

  const color = backgroundColor
    ? // @ts-ignore
      tailwindConfig.theme.colors.secondary[backgroundColor]
      ? // @ts-ignore
        tailwindConfig.theme.colors.secondary[backgroundColor]
      : tailwindConfig.theme.colors.secondary.sand
    : tailwindConfig.theme.colors.secondary.sand

  return (
    <section
      ref={ref}
      className="breakout-content-width relative w-full overflow-hidden py-40 lg:py-20"
    >
      <Jumplink id={id} />
      <BottomShape color={color as string} className={clsx('top-0 -z-20 xl:scale-x-110')} />
      <div
        style={{ backgroundColor: color }}
        ref={inViewRef}
        id="textContainer"
        className={clsx(
          'mx-auto grid max-w-[1920px] grid-cols-1 text-primary-black lg:translate-y-40 lg:grid-cols-5 lg:!bg-transparent ',
        )}
      >
        <div
          ref={textRef}
          id="container"
          className={clsx(
            'relative px-4 delay-100 duration-300 lg:col-span-2 lg:px-12 lg:pl-20',
            containerInView ? 'translate-y-0 opacity-100' : 'translate-y-5 opacity-0',
          )}
        >
          {headline ? (
            <Headline className={clsx('mb-6 lg:mb-10')} type="h2">
              {headline}
            </Headline>
          ) : null}
          {text ? <p className="mb-6 text-[20px] leading-[28px] lg:mb-10">{text}</p> : null}
          {cta ? <ContentfulButton {...cta} ctaStyle="MediumText" /> : null}
        </div>
        {backgroundText ? (
          <BackgroundText inView={isDesktop ? containerInView : textInView} text={backgroundText} />
        ) : (
          <div id="text"></div>
        )}
      </div>
      <div
        style={{ backgroundColor: color }}
        id="innerContainer"
        className={clsx(
          'relative z-[-10] -mt-20 w-full',
          hasSmallerBG ? 'h-[60vh] lg:h-[100vh]' : 'h-[90vh] lg:h-[140vh]',
        )}
      >
        {image1 ? (
          <div
            id="firstImage"
            className="relative ml-[8.5vw] w-[27vw] translate-y-[50vh] lg:ml-[20vw] lg:w-[23vw] lg:translate-y-[100vh]"
          >
            <Image
              src={image1?.url}
              alt={image1?.description as string}
              width={image1?.width as number}
              height={image1?.height as number}
              sizes="(min-width: 1024px) 23vw, 27vw"
            />
          </div>
        ) : null}
        {image2 ? (
          <div
            id="secondImage"
            className="absolute top-10 ml-[53vw] w-[47vw] translate-y-[20vh] lg:ml-[64vw] lg:w-[29vw] lg:translate-y-[50vh]"
          >
            <Image
              src={image2?.url}
              alt={image2?.description as string}
              width={image2?.width as number}
              height={image2?.height as number}
              sizes="(min-width: 1024px) 29vw, 47vw"
            />
          </div>
        ) : null}
        {image3 ? (
          <div
            id="thirdImage"
            className=" relative w-[26vw] translate-y-[35vh] lg:ml-[5.5vw] lg:w-[24vw] lg:translate-y-[60vh]"
          >
            <Image
              src={image3?.url as string}
              alt={image3?.description as string}
              width={image3?.width as number}
              height={image3?.height as number}
              sizes="(min-width: 1024px) 24vw, 26vw"
            />
          </div>
        ) : null}
        {image4 ? (
          <div
            id="fourthImage"
            className="absolute z-10 ml-[34vw] w-[42.5vw] translate-y-[30vh] lg:ml-[46vw] lg:w-[34.5vw] lg:translate-y-[30vh]"
          >
            <Image
              src={image4?.url as string}
              alt={image4?.description as string}
              width={image4?.width as number}
              height={image4?.height as number}
              sizes="(min-width: 1024px) 34.5vw, 42.5vw"
            />
          </div>
        ) : null}
        {image5 ? (
          <div
            id="fifthImage"
            className="absolute bottom-0 ml-[58vw] w-[42vw] translate-y-[-5vh] lg:ml-[77vw] lg:w-[21vw] lg:translate-y-[20vh]"
          >
            <Image
              src={image5?.url as string}
              alt={image5?.description as string}
              width={image5?.width as number}
              height={image5?.height as number}
              sizes="(min-width: 1024px) 21vw, 42vw"
            />
          </div>
        ) : null}
        {image6 ? (
          <div
            id="sixthImage"
            className=" relative ml-[14vw] w-[38vw] translate-y-[45vh] lg:ml-[32vw] lg:w-[20vw] lg:translate-y-[90vh]"
          >
            <Image
              src={image6?.url as string}
              alt={image6?.description as string}
              width={image6?.width as number}
              height={image6?.height as number}
              sizes="(min-width: 1024px) 20vw, 38vw"
            />
          </div>
        ) : null}
      </div>
      <BottomShape
        isBottom
        color={color as string}
        className={clsx('relative bottom-0 z-[-20] mt-[-2px]')}
        svgClasses={clsx('rotate-180')}
      />
    </section>
  )
}

export default EditorialGalleryContent
