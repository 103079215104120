import { useHiddenH1Query } from '@contentfulTypes'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'

type Props = {
  id: string
}

export const HiddenHeadingH1 = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const { data } = useHiddenH1Query(contentfulClient, {
    id,
    preview,
    locale: finalLocale,
  })

  return (
    <section>
      <h1 className="hidden">{data?.hiddenHeadingH1?.hiddenH1Item}</h1>
    </section>
  )
}

export default HiddenHeadingH1
