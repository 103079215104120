import { useMedia } from 'hooks/useMedia'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'lgx' | 'xl' | '2xl'

export const useMarginMaker = (mb: string, mbMobile: string, breakpoint: Breakpoint = 'lg') => {
  const isDesktop = useMedia(breakpoint)

  if (isDesktop) {
    switch (mb) {
      case 'none':
        return { marginBottom: 0 }
      case 'small':
        return { marginBottom: '32px' }
      case 'large':
        return { marginBottom: '128px' }
      default:
        return { marginBottom: '80px' }
    }
  } else {
    switch (mbMobile) {
      case 'none':
        return { marginBottom: 0 }
      case 'small':
        return { marginBottom: '16px' }
      case 'large':
        return { marginBottom: '64px' }
      default:
        return { marginBottom: '32px' }
    }
  }
}
