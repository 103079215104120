import { Image } from '~/elements/Image/Image'
import { MouseEventHandler, CSSProperties } from 'react'
import clsx from 'clsx'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
  isPrev?: boolean
  className?: string
  style?: CSSProperties
}

export const SliderButton = ({ onClick, isPrev = false, className, style }: Props) => {
  return (
    <button
      style={style}
      onClick={onClick}
      className={clsx(
        'absolute z-10 flex h-10 w-10 items-center justify-center rounded-full bg-grey-light',
        isPrev ? 'left-0 lg:-translate-x-6' : 'right-0 rotate-180 lg:translate-x-6',
        className,
      )}
    >
      <Image
        src="/images/Icons/chevron-left.svg"
        width={24}
        height={24}
        placeholder="empty"
        alt="chevron-left-icon"
      />
    </button>
  )
}
