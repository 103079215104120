import { useRef, useEffect, useContext, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper'
import { Navigation } from 'swiper'
import { ProductCard } from '~/modules/PLP/components/ProductCard'
import { ConfigurableProduct, useGetProductsViaSkuQuery } from '@magentoTypes'
import { ShopTheLookItem, InputMaybe } from '@contentfulTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createMagentoClient } from '~/graphql/magentoClient'
import { STLContext } from '../context/STLStore'
import { SliderButton } from '~/elements/SliderButton/SliderButton'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import dynamic from 'next/dynamic'

const LazyInViewProductRatingLoader = dynamic(
  () => import('../../ClientReviews/components/InViewProductRatingLoader'),
  { ssr: false },
)

type Props = {
  items: ShopTheLookItem[]
}

export const Slider = ({ items }: Props) => {
  const [sliderImageHeight, setSliderImageHeight] = useState(0)
  const skus = items.map((item: ShopTheLookItem) => item?.sku as InputMaybe<string>)
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const { data } = useGetProductsViaSkuQuery(magentoClient, { sku: skus })
  const { activeIndex, setActiveIndex } = useContext(STLContext)
  const [{ isEnd, isBeginning }, setSwiperPositon] = useState({
    isEnd: false,
    isBeginning: true,
  })
  const swiperRef = useRef<SwiperType | null>(null)
  const swipeToSlide = (i: number) => {
    swiperRef?.current?.slideTo(i)
  }

  useEffect(() => {
    swipeToSlide(activeIndex as number)
  }, [activeIndex])

  const getImageHeight = (val: number) => {
    setSliderImageHeight(val)
  }

  return (
    <div className="relative">
      <LazyInViewProductRatingLoader />
      <Swiper
        modules={[Navigation]}
        slidesPerView={1}
        centeredSlides
        allowTouchMove
        onBeforeInit={(swiper) => (swiperRef.current = swiper)}
        onTransitionEnd={() => {
          const sliderIndex = swiperRef?.current?.realIndex
          setActiveIndex?.(sliderIndex as number)
        }}
        onInit={({ isBeginning, isEnd }) => setSwiperPositon({ isBeginning, isEnd })}
        onSlideChange={({ isBeginning, isEnd }) => setSwiperPositon({ isBeginning, isEnd })}
      >
        {data?.products?.items?.map((item) => {
          return (
            <SwiperSlide key={item?.uid}>
              <ProductCard
                className="border-b-0"
                hasBorderTop={false}
                hasBorderRight={false}
                product={item as ConfigurableProduct}
                getImageHeight={getImageHeight}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      {data?.products?.items && data?.products?.items.length > 1 && (
        <SliderButton
          isPrev
          onClick={() => swiperRef?.current?.slidePrev()}
          className={clsx(isBeginning && 'cursor-default opacity-0')}
          style={{ top: `${sliderImageHeight / 2}px` }}
        />
      )}
      {data?.products?.items && data?.products?.items.length > 1 && (
        <SliderButton
          onClick={() => swiperRef?.current?.slideNext()}
          style={{ top: `${sliderImageHeight / 2}px` }}
          className={clsx(isEnd && 'cursor-default opacity-0', isBeginning && '!opacity-100')}
        />
      )}
    </div>
  )
}
