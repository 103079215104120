import EditorialGalleryContent from './components/EditorialGalleryContent'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { useEffect, useState } from 'react'

type Props = {
  id: string
}

const EditorialGallery = ({ id }: Props) => {
  const [viewPortWidth, setViewPortWidth] = useState<number | null>(null)
  useEffect(() => {
    setViewPortWidth(window.innerWidth)
  }, [])

  if (viewPortWidth === null) return null

  return (
    <ClientOnly>
      <EditorialGalleryContent id={id} isDesktop={viewPortWidth >= 1024} />
    </ClientOnly>
  )
}

export default EditorialGallery
