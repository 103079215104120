import { useSeoTextModuleQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { SeoText } from '../SeoText/SeoText'

const SeoTextModule = ({ id }: { id: string }) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useSeoTextModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const { items } = data?.seoTextModule?.seoTextsCollection ?? { items: [] }

  return (
    <div className="content-width py-[50px]">
      <section className="">
        {items.map((item, index) => (
          <SeoText
            key={index}
            className={index === items.length - 1 ? '' : 'mb-[50px]'}
            headline={item?.headline ?? ''}
            seoText={item?.seoText?.json}
            links={item?.seoText?.links}
          />
        ))}
      </section>
    </div>
  )
}

export default SeoTextModule
