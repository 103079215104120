import { ReactNode, useState } from 'react'
import { STLContext } from './STLStore'

type Props = {
  children: ReactNode
}

export const STLProvider = ({ children }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  return (
    <STLContext.Provider
      value={{
        setActiveIndex,
        activeIndex,
      }}
    >
      {children}
    </STLContext.Provider>
  )
}
