import { useState, useContext, useEffect, Dispatch } from 'react'
import { STLContext } from '../context/STLStore'
import { Asset, ShopTheLookItem } from '@contentfulTypes'
import { Image } from '~/elements/Image/Image'
import { useMedia } from 'hooks/useMedia'
import { Tooltip } from './Tooltip'
import clsx from 'clsx'

type Props = {
  image: Asset
  buttons?: ShopTheLookItem[]
  layoutFill?: boolean
  keepImageSize?: boolean
  className?: string
  isSlider?: boolean
  imageFocus?: string | null | undefined
  imageFocusMobile?: string | null | undefined
}

export const GridItem = ({
  image,
  buttons,
  className,
  layoutFill = false,
  keepImageSize = false,
  isSlider = false,
  imageFocus,
  imageFocusMobile,
}: Props) => {
  const isDesktop = useMedia('lg')
  const { activeIndex, setActiveIndex } = useContext(STLContext)
  const [activeId, setActiveId] = useState<string>('')

  const handleTooltipIndex = (i: number, id: string) => {
    setActiveIndex?.(i)
    setActiveId(id)
  }

  return (
    <div className={clsx('relative h-full w-full', className)}>
      <Image
        src={image?.url as string}
        alt={image?.description as string}
        width={keepImageSize ? (image?.width as number) : isSlider && !isDesktop ? 1200 : 1000}
        height={keepImageSize ? (image?.height as number) : isSlider && !isDesktop ? 1500 : 1500}
        fill={layoutFill && isDesktop ? true : false}
        style={{
          objectFit: 'cover',
          objectPosition: (isDesktop ? imageFocus : imageFocusMobile) || '',
        }}
        sizes="(min-width: 1024px) 50vw, 100vw"
      />
      <div className="absolute inset-0">
        {buttons?.map((item: ShopTheLookItem, i: number) => {
          return (
            <Tooltip
              isActive={isSlider ? i === activeIndex : activeId === item.sys.id}
              onClick={() => handleTooltipIndex(i, item.sys.id)}
              item={item}
              key={item?.sys?.id}
              isSlider={isSlider}
            />
          )
        })}
      </div>
    </div>
  )
}
