import { Maybe, Scalars } from '@contentfulTypes'
import { isExternal } from '~/lib/externalLink'
import { Link } from '~/elements/Link/Link'
import { log } from '~/lib/log'

type Props = {
  __typename?: 'Cta'
  linkToEntry?: Maybe<{ sys: { __typename?: 'Sys'; id: string } }>
  ctaText?: Maybe<Scalars['String']>
  eMailLink?: Maybe<Scalars['String']>
  linkExistingPage?:
    | {
        __typename?: 'Page' | undefined
        slug?: string | null | undefined
        title?: string | null | undefined
      }
    | null
    | undefined
  linkExternalPage?: Maybe<Scalars['String']>
  linkInternalUrl?: Maybe<Scalars['String']>
  phoneLink?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  children: React.ReactNode | JSX.Element
}

export const LinkableContainer = ({
  linkToEntry,
  eMailLink,
  linkExistingPage,
  linkExternalPage,
  linkInternalUrl,
  phoneLink,
  ctaText,
  title,
  children,
}: Props) => {
  if (linkInternalUrl) {
    return (
      <Link href={linkInternalUrl} title={title ?? ''}>
        {children}
      </Link>
    )
  }
  if (linkExternalPage && isExternal(linkExternalPage)) {
    return (
      <Link href={linkExternalPage} title={title ?? ''}>
        {children}
      </Link>
    )
  }

  if (linkExistingPage?.slug && !isExternal(linkExistingPage.slug) && !linkToEntry?.sys?.id) {
    return (
      <Link href={linkExistingPage.slug} title={linkExistingPage?.title ?? ''}>
        {children}
      </Link>
    )
  }
  if (phoneLink && !linkExistingPage?.slug && !linkExternalPage) {
    return (
      <Link href={`tel:${phoneLink}`} title={phoneLink ?? ctaText ?? ''}>
        {children}
      </Link>
    )
  }
  if (eMailLink && !linkExistingPage?.slug && !linkExternalPage) {
    return (
      <Link href={`mailto:${eMailLink}`} title={eMailLink ?? ctaText ?? ''}>
        {children}
      </Link>
    )
  }
  if (linkToEntry?.sys?.id && !linkExistingPage?.slug) {
    return <a href={`#${linkToEntry.sys.id}`}>{children}</a>
  }
  if (linkToEntry?.sys?.id && linkExistingPage?.slug) {
    log(`${linkExistingPage?.slug}/#${linkToEntry.sys.id}`)
    return <Link href={`${linkExistingPage?.slug}/#${linkToEntry.sys.id}`}>{children}</Link>
  }

  return <>{children}</>
}
