import { RteRteLinks, useRteFieldQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import PulseLoader from 'react-spinners/PulseLoader'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { RTEParser } from '~/lib/RTEParser/RTEParser'

type Props = {
  id: string
}

export const RTE = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data, isLoading } = useRteFieldQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <PulseLoader loading={true} size={6} color={'#909090'} />
      </div>
    )
  }

  return (
    <div className="relative">
      <Jumplink id={id} />
      <RTEParser
        rteJSONContent={data?.rte?.rte?.json}
        links={data?.rte?.rte?.links as RteRteLinks}
      />
    </div>
  )
}
