import { Suspense } from 'react'
import dynamic from 'next/dynamic'
import PulseLoader from 'react-spinners/PulseLoader'

type Props = {
  id: string
}

const Lookbook = ({ id }: Props) => {
  const LookbookFlipper = dynamic(() => import('~/modules/Lookbook/components/LookbookFlipper'), {
    suspense: true,
  })

  return (
    <section className="grid min-h-[calc(100vh-220px)] place-items-center">
      <Suspense
        fallback={
          <div className="flex h-[400px] items-center justify-center">
            <PulseLoader size={18} color={'#141414'} loading={true} />
          </div>
        }
      >
        <div className="h-full w-full">
          <LookbookFlipper id={id} />
        </div>
      </Suspense>
    </section>
  )
}

export default Lookbook
