import { useShopTheLookQuery, Asset, ShopTheLookItem } from '@contentfulTypes'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { useMedia } from 'hooks/useMedia'
import { Headline } from 'elements/Headline/Headline'
import { GridItem } from './components/GridItem'
import { Slider } from './components/Slider'
import { STLProvider } from './context/STLProvider'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const ShopTheLook = ({ id }: Props) => {
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data, error } = useShopTheLookQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const isDesktop = useMedia('lg')

  const margin = useMarginMaker(
    data?.shopTheLook?.marginBottom as string,
    data?.shopTheLook?.marginBottomMobile as string,
  )

  if (!data?.shopTheLook || error) return null
  const {
    headline,
    firstImage,
    secondImage,
    thirdImage,
    fourthImage,
    firstImageProductButtonsCollection,
    secondImageProductButtonsCollection,
    thirdImageProductButtonsCollection,
    fourthImageProductButtonsCollection,
    firstImageFocus,
    firstImageFocusMobile,
    secondImageFocus,
    secondImageFocusMobile,
    thirdImageFocus,
    thirdImageFocusMobile,
    fourthImageFocus,
    fourthImageFocusMobile,
    sys,
  } = data?.shopTheLook

  const singleItem = !secondImage && !thirdImage && !fourthImage

  return (
    <STLProvider>
      <section
        className="relative max-w-[100vw] overflow-hidden px-4 md:px-8 lg:px-20"
        style={margin}
      >
        <Jumplink id={id} />
        {headline ? (
          <Headline className="mb-4 lg:mb-8" type="h2">
            {headline}
          </Headline>
        ) : null}
        <div
          className={clsx(
            'grid w-full grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8',
            thirdImage || fourthImage ? 'lg:grid-rows-[repeat(2,auto)]' : 'lg:grid-rows-1',
            secondImage || thirdImage || fourthImage || (singleItem && !isDesktop)
              ? 'grid-flow-row'
              : 'grid-rows-[repeat(2,auto)]',
          )}
        >
          {firstImage ? (
            <GridItem
              className={clsx(
                'col-span-1',
                thirdImage || fourthImage ? 'lg:row-span-2' : 'row-span-1',
              )}
              image={firstImage as Asset}
              buttons={firstImageProductButtonsCollection?.items as ShopTheLookItem[]}
              layoutFill={singleItem && isDesktop ? true : false}
              isSlider={singleItem}
              imageFocus={firstImageFocus}
              imageFocusMobile={firstImageFocusMobile}
            />
          ) : null}
          {singleItem && firstImageProductButtonsCollection?.items?.length ? (
            <div className="col-span-1 row-span-1 text-center">
              <Slider items={firstImageProductButtonsCollection?.items as ShopTheLookItem[]} />
            </div>
          ) : null}
          {secondImage ? (
            <GridItem
              layoutFill={fourthImage || thirdImage ? true : false}
              keepImageSize={isDesktop ? false : true}
              className={clsx('row-span-1')}
              image={secondImage as Asset}
              buttons={secondImageProductButtonsCollection?.items as ShopTheLookItem[]}
              imageFocus={secondImageFocus}
              imageFocusMobile={secondImageFocusMobile}
            />
          ) : null}
          {singleItem ? null : (
            <div className={clsx('relative row-span-1 flex gap-x-4 lg:gap-x-8')}>
              {thirdImage ? (
                <GridItem
                  layoutFill
                  keepImageSize={fourthImage ? false : true}
                  className={clsx(fourthImage && 'h-full w-1/2')}
                  image={thirdImage as Asset}
                  buttons={thirdImageProductButtonsCollection?.items as ShopTheLookItem[]}
                  imageFocus={thirdImageFocus}
                  imageFocusMobile={thirdImageFocusMobile}
                />
              ) : null}
              {fourthImage ? (
                <GridItem
                  layoutFill
                  className={clsx('w-1/2')}
                  image={fourthImage as Asset}
                  buttons={fourthImageProductButtonsCollection?.items as ShopTheLookItem[]}
                  imageFocus={fourthImageFocus}
                  imageFocusMobile={fourthImageFocusMobile}
                />
              ) : null}
            </div>
          )}
        </div>
      </section>
    </STLProvider>
  )
}

export default ShopTheLook
