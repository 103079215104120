import ContentLoader from 'react-content-loader'
import { useMedia } from '~/hooks/useMedia'
import { useRef, useState } from 'react'
import clsx from 'clsx'

type PropsCards = {
  className?: string
}

const ProductCard = ({ className }: PropsCards) => {
  return (
    <figure
      className={clsx(
        'relative col-span-1 row-span-1 h-full border border-grey-border border-opacity-50 pb-3 pt-8 lg:pb-8 lg:pt-14',
        className,
      )}
    >
      <ContentLoader
        speed={3.3}
        viewBox="0 0 360 560"
        backgroundColor="#F4F4F4"
        foregroundColor="#CACACA"
        gradientRatio={1}
      >
        <rect x="30" y="0" rx="0" ry="0" width="300" height="420" />
        <rect x="70" y="440" rx="0" ry="0" width="220" height="10" />
        <rect x="60" y="460" rx="0" ry="0" width="240" height="10" />
        <rect x="70" y="480" rx="0" ry="0" width="220" height="10" />
        <circle cx="100" cy="530" r="12" />
        <circle cx="140" cy="530" r="12" />
        <circle cx="180" cy="530" r="12" />
        <circle cx="220" cy="530" r="12" />
        <circle cx="260" cy="530" r="12" />
      </ContentLoader>
    </figure>
  )
}

export const LoadingItems = ({}) => {
  const isDesktop = useMedia('lg')

  return (
    <section className="-mx-4 w-[100vw] md:-mx-8 lg:-mx-20 2xl:mx-0 2xl:w-full">
      <div
        className={clsx(
          'grid w-full grid-cols-1 lg:grid-cols-2',
          !isDesktop ? 'grid-flow-row' : 'grid-rows-1',
        )}
      >
        <ProductCard />
        <ProductCard />
      </div>
    </section>
  )
}
