import clsx from 'clsx'
import { useMedia } from 'hooks/useMedia'

type Props = {
  className?: string
  color?: string
}
export const StaticShape = ({ className, color }: Props) => {
  const isDesktop = useMedia('lgx')
  const isTabletMd = useMedia('md')
  const isTabletLg = useMedia('lg')
  return (
    <svg
      className={clsx(className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={
        isDesktop
          ? '0 0 1350 1500'
          : isTabletLg
          ? '0 0 1400 1700'
          : isTabletMd
          ? '0 0 1500 1800'
          : '0 0 1500 2600'
      }
    >
      <g transform={isTabletLg ? 'translate(0 150)' : 'translate(0 0)'}>
        <path
          d="M387.286 1151.43C274.851 1060.84 96.95 878.144 38.234 653.779-194.62-236.006 720.173 21.564 909.883 81.492 1099.59 141.42 1392.57 240.103 1462.88 389.27c70.32 149.167 76.33 436.504-292.4 759.48-361.005 316.2-670.76 93.28-783.194 2.68Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </svg>
  )
}
